import { fetcher } from "@/pages/api/util/fetcher";

export /**
 * get final price
 * @param price
 * @param discount
 * @returns
 */
  const getFinalPrice = (price, discount) => {
    let finalPrice;
    let numberPrice = Number(commaToDot(price) || 0);
    let numberDiscount = Number(commaToDot(discount) || 0);

    if (numberDiscount > 0) {
      finalPrice = numberPrice - numberPrice * (numberDiscount / 100);
    } else {
      finalPrice = numberPrice;
    }
    return finalPrice.toFixed(2);
  };

// Types for better type safety and documentation
type Season = 'SS' | 'FW';
type SeasonId = `${Season}${number}`;

interface SeasonConfig {
  startMonth: number;
  endMonth: number;
  prefix: Season;
}

// Seasons constants
const SEASONS: Record<Season, SeasonConfig> = {
  SS: {
    startMonth: 0,  // January
    endMonth: 6,    // July
    prefix: 'SS'
  },
  FW: {
    startMonth: 7,  // August
    endMonth: 11,   // December
    prefix: 'FW'
  }
};

/**
 * Generates an array of season IDs based on a given date
 * @param date - The reference date for determining seasons
 * @param includePrevious - Whether to include the previous season
 * @returns Array of season IDs in chronological order
 */
const getSeasonsId = (date: Date, includePrevious = false): SeasonId[] => {
  const month = date.getMonth();
  const year = date.getFullYear();
  
  // Determine current season
  const currentSeason = month >= SEASONS.SS.startMonth && month <= SEASONS.SS.endMonth
    ? SEASONS.SS
    : SEASONS.FW;
  
  // Generate seasons array
  const seasons: SeasonId[] = [];
  
  // Add previous season if requested
  if (includePrevious) {
    const previousPrefix = currentSeason.prefix === 'SS' ? 'FW' : 'SS';
    const previousYear = currentSeason.prefix === 'SS' ? year - 1 : year;
    seasons.push(`${previousPrefix}${previousYear}`);
  }
  
  // Add current season
  seasons.push(`${currentSeason.prefix}${year}`);
  
  // Add next season
  const nextPrefix = currentSeason.prefix === 'SS' ? 'FW' : 'SS';
  const nextYear = currentSeason.prefix === 'FW' ? year + 1 : year;
  seasons.push(`${nextPrefix}${nextYear}`);
  
  return seasons;
};

export { getSeasonsId, type SeasonId, type Season };

export /**
 * season id based on creation date of a product
 * @param product
 */
  const getSeasonsLabel = (season: string) => {
    if (season)
      switch (season.slice(0, 2)) {
        case "SS":
          return "spring-summer" + " " + season.slice(2, season.length);
        case "FW":
          return "autumn-winter" + " " + season.slice(2, season.length);
        default:
          return season;
      }
  };

export const getSorted = (values, options) => {
  return values.sort((a, b) => (getIndex(a, options) > getIndex(b, options) ? 1 : -1));
};

/**
 * Retrieves index based on size name
 * @param value
 * @returns  integer as index
 */
const getIndex = (value: any, options) => {
  return Number(options.find((el) => el.key == value)?.id);
};

// transform string to a number only if it's a string and if contains a dot replace it with a comma
export const commaToDot = (value: string) => {
  if (typeof value === "string" && value.includes(",")) {
    return value.replace(",", ".");
  }
  return value;
};


export const getCSVKollaBarcodes = async (codes) => {
  return await fetcher("/api/barcodes/export" + "?" + new URLSearchParams({ codes: codes.toString(',') }).toString());
};

export const getCSVProducts = async (season, startDate, endDate) => {
  return await fetcher("/api/product/export" + "?" + new URLSearchParams({ ...(season && { season }),  ...(startDate && { startDate }), ...(endDate && { endDate })}).toString());
};

export const setKollaDiscount = async (codes, discount) => {

  return await fetcher("/api/product/kolla-discount", {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({codes, discount}),
  });
};

